.react-datepicker-popper {
  width: 320px !important;
}

.react-datepicker__header {
  padding: 10px;
}

.react-datepicker__current-month,
.react-datepicker__current-year {
  width: 160px;
  font-size: 16px;
}

.react-datepicker__day-name {
  padding: 8px;
}

.react-datepicker__day {
  padding: 6px 0px;
  font-size: 14px;
  height: 30px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  font-size: 16px;
}

.react-datepicker__day--in-range {
  background-color: #e0e0e0;
  color: #333;
}

.react-datepicker__day--start {
  background-color: #2196f3;
  color: #fff;
}

.react-datepicker__day--end {
  background-color: #2196f3;
  color: #fff;
}

.react-datepicker__day--selected {
  background-color: #2196f3;
  color: #fff;
}

.react-datepicker__day--today {
  border: 1px solid #2196f3;
  border-radius: 40%;
  color: #1976d2;
  font-weight: bold;
}

.react-datepicker__close-icon {
  padding: 0 15px 15px 0;
}

.date-picker-field {
  display: flex;
  max-width: 164px;
}

.date-input {
  width: 100%;
  max-height: 46px;
  border: 1px solid #000000;
}

.calenderIcon-left {
  position: absolute;
  top: 9px;
  right: 22%;
}

.calenderIcon-right {
  position: absolute;
  top: 9px;
  right: 22%;
}

.week-btn {
  font-size: 12px !important;
  height: 44px !important;
  margin-right: 5px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
