body {
  margin: 0;
  font-family: "Noto_Sans" !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Noto_Sans"; */
  -webkit-font-smoothing: antialiased;
  background: #f5f4f7 !important;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none !important;
  color: #25282b;
}

code {
  font-family: "Noto_Sans";
}

@font-face {
  font-family: "Noto_Sans";
  src: url("./Components/Assets/Fonts/Noto_Sans/NotoSans-Medium.ttf");
}

.Noto_Sans {
  font-family: "Noto_Sans";
  font-style: normal;
  /* line-height: 4.3rem; */
}

/* Scrollbar */
::-webkit-scrollbar {
  border: 0;
  height: 1rem;
}

/* for scroll bar */
.ScrollBar {
  padding-right: 2rem;
  height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.8s ease-in-out;
}

::-webkit-scrollbar {
  width: 0.6rem !important;
  height: 0.6rem !important;
}
::-webkit-scrollbar:hover {
  width: 1rem !important;
  height: 1rem !important;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #1470a4 !important;
  border-radius: 1rem;
}

@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
  .ag-grid-size {
    height: calc(100vh - 160px) !important;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 33%;
  }
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fs-10 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 1.2rem !important;
}

.fs-13 {
  font-size: 1.3rem !important;
}

.fs-14 {
  font-size: 1.4rem !important;
}

.fs-16 {
  font-size: 1.6rem !important;
}

.fs-18 {
  font-size: 1.8rem !important;
}

.fs-20 {
  font-size: 2rem !important;
}

.fs-24 {
  font-size: 2.4rem !important;
}
.fs-28 {
  font-size: 2.8rem !important;
}

.fs-36 {
  font-size: 3.6rem !important;
}

.container {
  max-width: 153.6rem !important;
  padding: 2.4rem !important;
}

select,
textarea,
input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.3rem 1rem;
  gap: 1.3rem;
  width: 100%;
  height: 4.5rem;
  border: 0.1rem solid #ded2d9;
  border-radius: 0.5rem;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  margin-bottom: 1.8rem !important;
  font-size: medium !important;
  font-family: "Noto_Sans" !important;
}

.otp input {
  width: 4rem !important;
  margin-left: 0.5rem !important;
  font-family: "Noto_Sans" !important;
}

textarea {
  height: 22rem;
  font-family: "Noto_Sans" !important;
}

label {
  width: 6rem;
  height: 1.7rem;
  font-family: "Noto_Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #828282;
  margin-bottom: 0.5rem;
  font-family: "Noto_Sans" !important;
}

.eye-div {
  position: absolute;
  width: 5rem;
  height: 4.3rem;
  right: 0.1rem;
  top: 2rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.eye-div-2 {
  position: absolute;
  width: 5rem;
  height: 4.3rem;
  right: 0rem;
  top: 0rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.btn-primary {
  background: #1470a4 !important;
  gap: 1.3rem;
  padding: 1.3rem 1rem 1.2rem;
  border-radius: 0.6rem;
  font-family: "Noto_Sans" !important;
}

.Icon-profile .PhoneInputCountryIcon {
  width: 5.6rem;
  height: 4.6rem;
  padding: 0.7rem;
  margin-bottom: 1.8rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #d5d2de;
  background-color: rgb(238, 239, 243);
  font-family: "Noto_Sans" !important;
}

.Icon .PhoneInputCountryIcon {
  width: 5.6rem;
  height: 4.6rem;
  padding: 0.7rem;
  /* margin-bottom: 1.8rem; */
  border-radius: 0.4rem;
  border: 0.1rem solid #d5d2de;
  background-color: rgb(238, 239, 243);
  font-family: "Noto_Sans" !important;
}

.Icon input {
  margin-bottom: 0rem !important;
}

.login-check-box .form-check-input {
  margin-top: 0rem !important;
  height: 0.5rem !important;
}

/* grid-5 */
.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
}

@media (max-width: 1440px) {
  .grid-5 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1024px) {
  .grid-5 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 425px) {
  .grid-5 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* grid-2 */
.grid-2 {
  display: grid;
  grid-template-columns: 68% 31%;
  grid-gap: 2rem;
}

@media (max-width: 1440px) {
  .grid-2 {
    grid-template-columns: 70% 30%;
  }
}

@media (max-width: 1080px) {
  .grid-2 {
    grid-template-columns: 60% 40%;
  }
}

@media (max-width: 767px) {
  .grid-2 {
    grid-template-columns: 100%;
  }
}

.img-fill {
  width: 45vw;
  height: 100%;
  vertical-align: middle !important;
  /* object-fit: cover; */
}
.img-fil {
  width: 25vw;
  /* opacity: 0.5; */
  vertical-align: middle !important;
  /* object-fit: cover; */
}

.ticket-cards {
  background: #fff;
  border: 0.1rem solid #edeef2;
  border-radius: 0.8rem !important;
  padding: 1.6rem !important;
  height: 11.2rem !important;
  transition: ease-in-out 1s;
  font-family: "Noto_Sans" !important;
}

.filter-section {
  background: #dee6edb8 !important;
  color: black !important;
  padding: 2rem !important;
  font-family: "Noto_Sans" !important;
  transition: ease-in-out 2s;
}

.bg-style {
  border-radius: 99.9rem;
  background: #e6fff3b2;
}

.text-success {
  color: #069952 !important;
}

.text-warning {
  color: #eb272c !important;
}

.Icon-color {
  color: #38b6ff !important;
}

.icon-size {
  font-size: 4rem !important;
}

.text-primry {
  color: #1470a4 !important;
}

.btn-primary {
  background: #1470a4 !important;
}

.severity-dot {
  height: 1.3rem !important;
  width: 1.3rem !important;
  border-radius: 50% !important;
  right: 0.6rem !important;
  top: 1rem !important;
  /* display: inline-block; */
  position: absolute !important;
}

.dash-cards {
  background: #e7e5e5;
  box-shadow: 0rem 1.2rem 2.6rem 0rem rgba(16, 30, 115, 0.06);
  border-radius: 0.8rem;
  background: #fff;
  padding: 0.8rem;
  font-family: "Noto_Sans" !important;
  transition: ease-in-out 1s;
  width: 97% !important;
}
.input-height {
  height: 35px !important;
  font-size: 10px;
}

.filter-section .MuiTypography-root {
  color: #25282b !important;
  font-size: 1.4rem !important;
  overflow-wrap: break-word !important;
  font-family: "Noto_Sans" !important;
}
/* .filter-section .MuiTypography-root:hover {
  color: #25282b !important;
  font-size: 1.4rem !important;
  overflow-wrap: break-word !important;
  font-family: "Noto_Sans" !important;
} */

.dash-cards:hover {
  background: #e7e9f5 !important;
}

.black-line {
  border-top: 0.1rem solid black;
}

.max-h-40 {
  max-height: 4rem;
}

.ag-root-wrapper {
  border: none !important;
}

.h-70 {
  height: 7.5rem !important;
}

.h-95 {
  height: 95% !important;
}

.vh100-card {
  height: calc(100vh - 11rem) !important;
  overflow-x: scroll;
}

.vh100-login {
  height: calc(100vh - 6rem) !important;
  overflow-x: scroll;
}

.ag-grid-size {
  width: "100%" !important;
  height: calc(100vh - 32.7rem) !important;
  /* height: calc(100vh - 46.9rem) !important; */
}

.h-45 {
  height: 4.5rem !important;
}

.h-250 {
  height: 25rem !important;
}

.h-450 {
  max-height: 45rem !important;
  overflow: scroll !important;
}

.h-600 {
  height: calc(100vh - 16rem) !important;
  overflow-y: auto !important;
}

.h-550 {
  height: calc(100vh - 32rem) !important;
  overflow-y: scroll auto !important;
  overflow-x: auto !important;
}

@media (min-width: 1440px) {
  .vh100-card {
    height: calc(100vh - 17.8rem) !important;
  }
  .h-600 {
    height: calc(100vh - 22rem) !important;
    overflow-y: auto !important;
  }
  .h-550 {
    height: calc(100vh - 32rem) !important;
    overflow-y: auto !important;
  }
}

.brdr-right {
  border-right: 0.1rem solid #bfbfbf;
}

.brdr-right-left {
  border-right: 0.1rem solid #bfbfbf;
  border-left: 0.1rem solid #bfbfbf;
}

.info-card {
  padding: 0.8rem;
  border-radius: 1.2rem;
  border: 0.1rem solid #bfbfbf;
  background: white;
}

.audio-card {
  box-shadow: 0rem 1.2rem 2.6rem 0rem rgba(16, 30, 115, 0.06);
  margin-bottom: 2.5rem;
  padding: 0.8rem;
  transition: ease-in-out 1s;
  /* border-radius: 1.2rem; */
  /* border: .1rem solid #bfbfbf; */
  /* background: white; */
}
.audio-card input {
  margin-bottom: 0rem !important;
}
.audio-card:hover {
  transform: scale(1.01);
  background: rgba(33, 150, 243, 0.1);
  /* color: #ededed; */
  border-radius: 1rem;
}

.date-picker fieldset {
  margin-top: 0.1rem !important;
  margin-bottom: 0rem !important;
  height: 5rem !important;
  border-color: #ded2d9 !important;
}
.date-picker input {
  margin-bottom: 0rem !important;
  height: 1.2rem !important;
  font-size: 1.6rem !important;
}

.chip-styles {
  border: 0.1rem solid #1470a4 !important;
  transition: ease-in-out 1s;
}

.selected-button {
  background: #1470a4 !important;
  color: #ffffff !important;
  transition: ease-in-out 1s;
}

.chip-styles ::selection {
  background: #1470a4 !important;
  color: white !important;
  box-shadow: 0rem 1.2rem 2.6rem rgba(16, 30, 115, 0.06) !important;
  transform: scale(1.2);
}

.brdr-bottom {
  border-bottom: 0.2rem solid #1470a4 !important;
}

.button-styles {
  border-bottom: 0.1rem solid #1470a4 !important;
  transition: ease-in-out 1s;
}

.notes-form input,
.notes-form .btn {
  height: 3.2rem !important;
}

.brdr-primry {
  border: 0.1rem solid #1470a4 !important;
}

.numberField label + .css-v4u5dn-MuiInputBase-root-MuiInput-root,
.numberField .css-nz481w-MuiInputBase-input-MuiInput-input,
.numberField input {
  margin: 0rem !important;
  justify-content: center !important;
  font-size: 1.6rem !important;
  font-family: "Noto_Sans";
}

.apexcharts-legend-text {
  text-transform: capitalize !important;
  font-family: "Noto_Sans";
  font-size: 1.4rem !important;
  color: #52575c !important;
}

.w-80 {
  width: 8rem !important;
}

.w-87 {
  width: 8.7rem !important;
}

.w-11 {
  width: 11rem !important;
}

.w-180 {
  width: 18rem !important;
}

.h-40 {
  height: 4rem !important;
}

.h-45 {
  height: 4.5rem !important;
}

.h-28 {
  height: 2.8rem !important;
}

.h-32 {
  height: 3.2rem !important;
}

.Toastify__toast-theme--light {
  font-size: 1.4rem !important;
  font-family: "Noto_Sans";
}

.transition-loading {
  transition: all 0.6s ease-in-out;
}

.absolute-center-loader {
  position: absolute !important;
  z-index: 1;
  left: 50% !important;
}

.absolute-center-noData {
  position: absolute !important;
  z-index: 1;
  left: 45% !important;
}

.chip-severity {
  width: 8.4rem !important;
  height: 2.4rem !important;
  padding: 0.6rem 1.2rem;
  border-radius: 4rem;
}

.audio-card .btn {
  border-radius: 1.5rem !important;
}

.grid-table .btn {
  line-height: 2 !important;
}

.btn {
  border-radius: 1rem !important;
  line-height: 0.6 !important;
}

.tab-buttons {
  width: 9rem;
}

.helper {
  --reactour-accent: #1470a4;
  line-height: 1.3;
  color: #2d2323;
}

.scrollable-hours {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 20rem;
}

.hour-button {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  margin: 0.2rem;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5rem;
  cursor: pointer;
  transition: ease-in-out 2s;
}

.hour-button::selection {
  background-color: #2980b9;
}
